
/* this look like the OLD tab oriented UI ..not used ANYMORE */
/*
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
*/

/* used by WixLoginBar */
.loginout {
  text-decoration: none; 
}

/* these are for the old Green Plan Table..which I don't use anymore */
/* KAD added this for the packages Table */
td {
    padding: 5px;
    word-wrap: break-word;
}

.plantable tbody tr td {
    text-align: center;
}

.plantable tbody tr:first-child {
    background-color: #32a111;
    color: #FFFFFF;
}

td.hl {
    background-color: #dfeea3;
}
/* these are for the old Green Plan Table..which I don't use anymore */

/* used in CreateEdit under SuperAppObject */
.form-control {
    padding: .2rem .75rem !important;
    width: 98% !important;
    border-radius: .5rem !important;
}

.form-control:focus {
    border: 2px solid #007bff !important;
}

.mb-3 input:not(:placeholder-shown)~label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

.mb-3 input:-webkit-autofill~label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

.MuiGrid-grid-xs-12 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    display: grid !important;
    height: 100% !important;
}
/* think this might be used by SuperAppObject to set the header Font Size...probably should move to GlobalContext
.rdt_TableHeadRow {
    font-size: 20px;
}

/* for the ShowAgent component */
.myIframe {
    border: 0;
    border-radius: 20px;
    overflow: hidden;
    width: -webkit-fill-available;
}

.XXXcolStyle {
    width: 50%;
    border-style: solid;
    border-color: black;
    border-width: 2px 2px 0px 2px;
    /* 25px top, 10px right, 4px bottom and 35px left */
    padding: 10px;
}

.XXXtopBorder {
    width: 50%;
    border-style: solid;
    border-color: black;
    border-width: 2px 0px 0px 0px;
    /* 25px top, 10px right, 4px bottom and 35px left */
}

.XXXcolStyle100 {
    width: 100%;
    border-style: solid;
    border-color: black;
    border-width: 2px 2px 0px 2px;
    /* 25px top, 10px right, 4px bottom and 35px left */
    padding: 10px;
}
