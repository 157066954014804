@media (max-width: 991px){
  
    .main-header .navbar-toggler{
        padding: 0;
        border: 0;
        outline: none;
        filter: brightness(0) invert(1);
    }
    .main-header .navbar-collapse{
        position: fixed;
        padding: 35px 0 24px;
        overflow-x: hidden;
        overflow-y: auto;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 240px;
        background-color: var(--sec-bg);
        height: 100%;
        z-index: 99;
        display: none !important;
        border-left: 1px solid var(--subscription-box-border);
    }
    .main-header .navbar-collapse:not(.show){
        display: block !important;
    }
    .main-header .navbar-collapse .navbar-nav{
        height: 100%;
        align-items: unset;
    }
    .main-header .navbar-collapse .close-btn{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        border: 0;
        font-size: 20px;
        outline: none;
        background-color: transparent;
        color: var(--light-color);
      }
      .main-header .navbar-nav .navbar-text{
        padding: 10px 16px !important;
      }
      .main-header .navbar-nav .nav-link:has(.after-login-user-info){
        display: flex;
        justify-content: center;
      }
      .after-login-user-dropdown {
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
    }
    .authentication-page .back-btn {
      left: 16px;
      top: 16px;
      font-size: 16px;
    }
}
@media (max-width: 575px){
  .main-header .navbar-collapse{
    max-width: unset;
  }
}