:root {
  --primary-gradient-color: linear-gradient(
    217.56deg,
    #785eff -39.41%,
    #4d35ff 99.53%
  );
  --primary-fade-color1: #9cacdb;
  --secondary-gradient-color: linear-gradient(
    72deg,
    var(--mostPopular-tag-gradient2) -21.99%,
    var(--mostPopular-tag-gradient2) 98.93%
  );
  --primary-fade-color1: #9cacdb;
  --secondary-gradient-color: linear-gradient(
    72deg,
    var(--mostPopular-tag-gradient2) -21.99%,
    var(--mostPopular-tag-gradient2) 98.93%
  );
  --primary-fade-color1: #9cacdb;
  --secondary-color: #764ae2;
  --dark-color: #000;
  --light-color: #fff;
  --placeholder-color: #a9a9a9;
  --light-faeed-text-color: #bdc1e7;
  --light-faeed-text-color2: #c1c8dc;
  --light-faeed-text-color3: #9ba5c0;
  --light-faeed-text-color4: #a7abd3;
  --light-faeed-text-color5: #afb5e7;
  --input-light-bg: transparent;
  --divider-bg1: #273255;
  --divider-bg2: #3d4a72;
  --link-color: #4d35ff;
  --paragraph-gray1: #4a4a4a;
  --header-color: #f4f4f4;
  --subscription-box-border: #3b3f65;
  --table-card-wrapper-bg: #2f3354;
  --subscription-box-bg: #090b21;
  --subscription-box-active-border: #764ae2;
  --subscription-box-active-diver: #754fd1;
  --subscription-box-active-bg: linear-gradient(
    172.41deg,
    rgba(106, 42, 255, 0.8) -2.2%,
    rgba(85, 68, 255, 0.8) 56.18%
  );
  --white-shadow-text-blue: rgba(84, 58, 255, 0.9);
  --tag-box-border: rgba(255, 255, 255, 0.25);
  --subscription-h2-gradient1: #fff;
  --subscription-h2-gradient2: #715fff;
  --mostPopular-tag-gradient2: #555deb;
  --mostPopular-tag-gradient2: #9355eb;
  --table-header-filter-icon-btn-bg: #6069b2;
  --table-th: #d1d6ff;
  --theme-checkbox-s1-border: #aaabc2;
  --theme-checkbox-s2-disabled: #d1d6ff;
  --table-th-border: #6d75b4;
  --table-td-border: #5c6397;
  --table-pagination-no-active: #3b448c;
  --gray-border1: #dfdfdf;
  --modal-title: #121212;
  --dark-label: #323232;
  --modal-backdrop: #121212;
  --primary-gradient-shadow: #4d33ff99;
  --user-dropdown-shadow: #8f7cff1a;
  --back-btn-border: #676495;
  --sec-bg: #202345;
  --table-listing-page-wrapper-border: #464e8acc;
  --table-tr-border: #6f78c8;
  --table-row-gradient-bg: linear-gradient(
    217.56deg,
    #6e52ff -39.41%,
    #432aff 99.53%
  );
  /* --table-even-bg: #8080809c; */
  /* --table-odd-bg: #fff; */
  --table-even-bg: rgb(121, 121, 121);
  --table-odd-bg: white;
  /* --table-even-color: rgb(93, 93, 93); */
  --table-odd-color: #b78cf3;
  --table-even-color: #b78cf3;
  /* --table-even-color: #9355eb; */
}

html {
  scroll-behavior: smooth;
}

body .btn {
  border-radius: 60px;
}

/* ==================== start: fonts ==================== */
*,
*::after,
*::before,
body {
  font-family: "Inter", sans-serif;
}

body a {
  /* color: var(--link-color); */
}

body a:hover {
  color: #449af6;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Regular.eot");
  src: local("Roobert PRO Regular"), local("RoobertPRO-Regular"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Bold.eot");
  src: local("Roobert PRO Bold"), local("RoobertPRO-Bold"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Light.eot");
  src: local("Roobert PRO Light"), local("RoobertPRO-Light"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Medium.eot");
  src: local("Roobert PRO Medium"), local("RoobertPRO-Medium"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* ==================== end: fonts ==================== */

input.MuiInputBase-input:-webkit-autofill,
input.MuiInputBase-input:-webkit-autofill:hover,
input.MuiInputBase-input:-webkit-autofill:focus,
input.MuiInputBase-input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--light-color);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.link-color {
  color: var(--link-color) !important;
}

/* ==================== start: fonts ==================== */
*,
*::after,
*::before,
body {
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Regular.eot");
  src: local("Roobert PRO Regular"), local("RoobertPRO-Regular"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Bold.eot");
  src: local("Roobert PRO Bold"), local("RoobertPRO-Bold"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Light.eot");
  src: local("Roobert PRO Light"), local("RoobertPRO-Light"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert PRO";
  src: url("./assets/fonts/roobert-pro/RoobertPRO-Medium.eot");
  src: local("Roobert PRO Medium"), local("RoobertPRO-Medium"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.woff2") format("woff2"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.woff") format("woff"),
    url("./assets/fonts/roobert-pro/RoobertPRO-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* ==================== end: fonts ==================== */
.link-color {
  color: var(--link-color) !important;
}

body #root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-body-content {
  /* background-image: url(./assets/images/body-bg.png); */
  background-image: linear-gradient(
    to right bottom,
    #191b5b,
    #0f1a4c,
    #0b173c,
    #0b132c,
    #090d1c
  );
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: bottom center !important;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* .main-body-content:has(.authentication-page) {
  background-image: url(./assets/images/body-bg-for-authentication.png);
} */

.main-body-content:has(.alert-msg-s1) {
  min-height: 540px;
}

.main-body-content .alert-msg-s1 {
  margin-top: auto;
  margin-bottom: auto;
}

.form-group {
  margin-bottom: 20px !important;
}

.form-group label {
  color: var(--light-color);
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.2px !important;
  text-transform: capitalize;
  display: block;
  padding: 0 !important;
  margin-bottom: 8px !important;
}

.form-group label small,
.file-uploader-s1 .text small {
  font-size: 14px !important;
  font-weight: inherit;
  text-transform: lowercase;
}

@media (max-width: 575px) {
  .form-group label {
    font-size: 14px;
  }
}

body .form-control {
  border-radius: 4px !important;
  background-color: var(--input-light-bg);
  color: var(--light-color);
  height: 52px;
  font-weight: 400;
  width: 100% !important;
  border: 1px solid var(--light-color) !important;
  transition: 0.3s;
  padding: 0 16px;
}

body select.form-control option {
  color: var(--dark-color) !important;
}

body .form-control:disabled,
body .form-control[readonly] {
  background-color: var(--input-light-bg);
}

body .form-control::placeholder {
  color: var(--placeholder-color);
  font-style: italic;
}

body .form-control:focus {
  border: 1px solid var(--light-color) !important;
  color: var(--light-color);
  box-shadow: none !important;
  background-color: var(--input-light-bg);
}

@media (max-width: 575px) {
  body .form-control {
    font-size: 14px;
    height: 48px;
  }
}

.form-group .MuiInputBase-root:not(:has(textarea)),
.form-group .MuiInputBase-root .MuiSelect-nativeInput:not(:has(textarea)) {
  height: 52px;
}

.form-group .MuiInputBase-input:not(textarea) {
  border-radius: 4px !important;
  background-color: transparent;
  height: 52px;
  width: 100% !important;
  border: 0 !important;
  transition: 0.3s;
  /* padding: 0 16px; */
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}

body .MuiInputBase-root {
  color: var(--light-color) !important;
}

body .MuiFormControl-root fieldset {
  border-color: var(--light-color) !important;
}

body .MuiFormControl-root label {
  color: var(--light-color) !important;
}

@media (max-width: 575px) {
  .form-group input {
    font-size: 14px;
    height: 48px;
  }
}

.form-group .form-control.select1 {
  appearance: none;
  background-image: url(./assets/images/select1.png) !important;
  background-repeat: no-repeat !important;
  background-position: center right 16px !important;
  padding-right: 36px !important;
}

.form-group textarea.form-control {
  height: 113px;
  padding-top: 10px !important;
}

.input-with-icon {
  position: relative;
}

.input-with-icon.left-icon .left-icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: var(--light-color);
}

.input-with-icon.right-icon .right-icon {
  position: absolute;
  top: 50%;
  border: 0;
  padding: 0;
  background-color: transparent;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: var(--light-color);
}

.input-with-icon.left-icon .signup-left-icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: var(--dark-color);
}

.input-with-icon.right-icon .signup-right-icon {
  position: absolute;
  top: 50%;
  border: 0;
  padding: 0;
  background-color: transparent;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: var(--dark-color);
}

.checkagree {
  display: flex;
  align-items: center;
  column-gap: 9px;
}

.checkagree .checkcol {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.checkagree label,
.checkagree .label-text {
  color: #aaabc2;
  font-family: "Roobert PRO" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  margin: 0 !important;
}

.theme-checkbox-s1,
.table-listing-page-wrapper input[type="checkbox"] {
  appearance: none !important;
  border: 1px solid var(--theme-checkbox-s1-border) !important;
  width: 18px !important;
  height: 18px !important;
  flex: 0 0 auto !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  cursor: pointer !important;
}

.theme-checkbox-s1:checked,
.theme-checkbox-s1[custom-checked="true"]:checked,
.table-listing-page-wrapper input[type="checkbox"]:checked {
  background: var(--link-color) !important;
  border-color: var(--link-color) !important;
}

.theme-checkbox-s1:checked::after,
.theme-checkbox-s1[custom-checked="true"]::after,
.table-listing-page-wrapper input[type="checkbox"]:checked::after {
  content: "" !important;
  clear: both !important;
  height: 12px !important;
  display: block !important;
  width: 8px !important;
  border-bottom: 3px solid var(--light-color) !important;
  border-right: 3px solid var(--light-color) !important;
  transform: rotate(45deg) !important;
  margin: 0 0 5px !important;
  border-radius: 0 !important;
}

.theme-checkbox-s2 {
  appearance: none;
  border: 1px solid var(--light-color);
  background-color: var(--light-color);
  border-radius: 3px;
  width: 18px;
  height: 18px;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.theme-checkbox-s2:checked,
.theme-checkbox-s2[custom-checked="true"] {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.theme-checkbox-s2:checked::after,
.theme-checkbox-s2[custom-checked="true"]::after {
  content: "";
  clear: both;
  height: 12px;
  display: block;
  width: 8px;
  border-bottom: 3px solid var(--light-color);
  border-right: 3px solid var(--light-color);
  transform: rotate(45deg);
  margin: 0 0 5px;
}

.theme-checkbox-s2:disabled {
  pointer-events: none;
  background-color: var(--theme-checkbox-s2-disabled);
  border-color: var(--theme-checkbox-s2-disabled);
}

/* =================== start: main-banner =================== */
.main-banner {
  padding: 80px 0;
}

@media (max-width: 1350px) {
  .main-banner {
    padding: 55px 0;
  }
}

@media (max-width: 767px) {
  .main-banner {
    padding: 24px 0 42px;
  }
}

.main-banner .custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 100px;
}

@media (max-width: 991px) {
  .main-banner .custom-container {
    flex-wrap: wrap-reverse;
    justify-content: unset;
    align-items: unset;
    row-gap: 32px;
  }
}

@media (max-width: 767px) {
  .main-banner .custom-container {
    row-gap: 24px;
  }
}

.main-banner .text-contianer {
  width: 100%;
  max-width: 620px;
}

@media (max-width: 991px) {
  .main-banner .text-contianer {
    max-width: unset;
    text-align: center;
  }
}

.main-banner .text-contianer h1 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-family: Inter;
  font-size: 70px;
  font-weight: 600;
  line-height: 120%;
}

@media (max-width: 1350px) {
  .main-banner .text-contianer h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .main-banner .text-contianer h1 {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .main-banner .text-contianer h1 {
    font-size: 35px;
  }
}

.main-banner .text-contianer p {
  color: var(--light-faeed-text-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 21px;
}

@media (max-width: 767px) {
  .main-banner .text-contianer p {
    font-size: 16px;
    line-height: 28px;
    margin-top: 16px;
  }
}

.main-banner .text-contianer .more-action {
  display: flex;
  align-items: center;
  margin-top: 42px;
}

@media (max-width: 991px) {
  .main-banner .text-contianer .more-action {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .main-banner .text-contianer .more-action {
    margin-top: 24px;
  }
}

.main-banner .image-contianer {
  width: 100%;
  max-width: calc(100% - 620px);
}

@media (max-width: 991px) {
  .main-banner .image-contianer {
    max-width: 60vw;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .main-banner .image-contianer {
    max-width: 80vw;
  }
}

.main-banner .image-contianer img {
  width: 100%;
}

/* =================== start: main-banner =================== */
.main-banner {
  padding: 80px 0;
}

@media (max-width: 1350px) {
  .main-banner {
    padding: 55px 0;
  }
}

@media (max-width: 767px) {
  .main-banner {
    padding: 24px 0 42px;
  }
}

.main-banner .custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 100px;
}

@media (max-width: 991px) {
  .main-banner .custom-container {
    flex-wrap: wrap-reverse;
    justify-content: unset;
    align-items: unset;
    row-gap: 32px;
  }
}

@media (max-width: 767px) {
  .main-banner .custom-container {
    row-gap: 24px;
  }
}

.main-banner .text-contianer {
  width: 100%;
  max-width: 620px;
}

@media (max-width: 991px) {
  .main-banner .text-contianer {
    max-width: unset;
    text-align: center;
  }
}

.main-banner .text-contianer h1 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-family: Inter;
  font-size: 70px;
  font-weight: 600;
  line-height: 120%;
}

@media (max-width: 1350px) {
  .main-banner .text-contianer h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .main-banner .text-contianer h1 {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .main-banner .text-contianer h1 {
    font-size: 35px;
  }
}

.main-banner .text-contianer p {
  color: var(--light-faeed-text-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 21px;
}

@media (max-width: 767px) {
  .main-banner .text-contianer p {
    font-size: 16px;
    line-height: 28px;
    margin-top: 16px;
  }
}

.main-banner .text-contianer .more-action {
  display: flex;
  align-items: center;
  margin-top: 42px;
}

@media (max-width: 991px) {
  .main-banner .text-contianer .more-action {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .main-banner .text-contianer .more-action {
    margin-top: 24px;
  }
}

.main-banner .image-contianer {
  width: 100%;
  max-width: calc(100% - 620px);
}

@media (max-width: 991px) {
  .main-banner .image-contianer {
    max-width: 60vw;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .main-banner .image-contianer {
    max-width: 80vw;
  }
}

.main-banner .image-contianer img {
  width: 100%;
}

/* =================== end: main-banner =================== */
.genAiSolution-Welcome-sec {
  padding: 0 0 100px;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec {
    padding: 0 0 65px;
  }
}

.genAiSolution-Welcome-sec .custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 90px;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec .custom-container {
    column-gap: 50px;
  }
}

@media (max-width: 991px) {
  .genAiSolution-Welcome-sec .custom-container {
    flex-wrap: wrap;
    row-gap: 32px;
  }
}

.genAiSolution-Welcome-sec .custom-container .image-container {
  width: 100%;
  max-width: 716px;
  height: 752px;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec .custom-container .image-container {
    height: 700px;
  }
}

@media (max-width: 991px) {
  .genAiSolution-Welcome-sec .custom-container .image-container {
    max-width: 520px;
    height: auto;
  }
}

@media (max-width: 575px) {
  .genAiSolution-Welcome-sec .custom-container .image-container {
    max-width: 420px;
  }
}

.genAiSolution-Welcome-sec .custom-container .image-container img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.genAiSolution-Welcome-sec .custom-container .text-container {
  width: 100%;
  max-width: 705px;
}

@media (max-width: 991px) {
  .genAiSolution-Welcome-sec .custom-container .text-container {
    max-width: unset;
  }
}

.genAiSolution-Welcome-sec .custom-container .text-container .tag-area {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.genAiSolution-Welcome-sec
  .custom-container
  .text-container
  .tag-area
  .tag-box {
  color: var(--light-color);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 30.6px;
  display: flex;
  padding: 5px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
  border: 1px solid var(--tag-box-border);
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .tag-area
    .tag-box {
    font-size: 16px;
    line-height: 28px;
  }
}

.genAiSolution-Welcome-sec .custom-container .text-container h1 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec .custom-container .text-container h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .genAiSolution-Welcome-sec .custom-container .text-container h1 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 16px;
  }
}

.genAiSolution-Welcome-sec .custom-container .text-container p {
  color: var(--light-faeed-text-color4);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec .custom-container .text-container p {
    font-size: 14px;
    line-height: 25px;
  }
}

.genAiSolution-Welcome-sec
  .custom-container
  .text-container
  .icon-with-text-lists {
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  padding: 0px;
}

.genAiSolution-Welcome-sec
  .custom-container
  .text-container
  .icon-with-text-lists
  li {
  display: flex;
  align-items: center;
  column-gap: 18px;
  padding: 24px 0;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .icon-with-text-lists
    li {
    column-gap: 14px;
    padding: 20px 0;
  }
}

@media (max-width: 575px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .icon-with-text-lists
    li {
    padding: 16px 0;
    column-gap: 12px;
  }
}

.genAiSolution-Welcome-sec
  .custom-container
  .text-container
  .icon-with-text-lists
  li:not(:last-child) {
  border-bottom: 1px solid var(--divider-bg2);
}

.genAiSolution-Welcome-sec
  .custom-container
  .text-container
  .icon-with-text-lists
  li
  .icon {
  flex: 0 0 auto;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .icon-with-text-lists
    li
    .icon
    svg {
    width: 35px;
    height: auto;
  }
}

@media (max-width: 575px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .icon-with-text-lists
    li
    .icon
    svg {
    width: 32px;
  }
}

.genAiSolution-Welcome-sec
  .custom-container
  .text-container
  .icon-with-text-lists
  li
  .text
  h4 {
  color: var(--light-faeed-text-color2);
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
}

@media (max-width: 1350px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .icon-with-text-lists
    li
    .text
    h4 {
    font-size: 16px;
    line-height: 27px;
  }
}

@media (max-width: 575px) {
  .genAiSolution-Welcome-sec
    .custom-container
    .text-container
    .icon-with-text-lists
    li
    .text
    h4 {
    font-size: 14px;
    line-height: 24px;
  }
}

.custom-container {
  width: 100%;
  max-width: calc(1560px + 100px);
  padding-left: 50px;
  padding-right: 50px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .custom-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 575px) {
  .custom-container {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.toggle-menu-btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 22px;
  cursor: pointer;
  background: transparent;
  flex: 0 0 auto;
  border: 0;
}

@media (max-width: 575px) {
  .toggle-menu-btn1 {
    width: 24px;
  }
}

.toggle-menu-btn1 div {
  width: 100%;
  height: 1.83px;
  background: var(--primary-gradient-color);
  position: relative;
  transition: 0.5s;
}

.toggle-menu-btn1 div::before,
.toggle-menu-btn1 div::after {
  content: "";
  position: absolute;
  left: 0;
  width: inherit;
  height: inherit;
  background: var(--primary-gradient-color);
  border-radius: 3px;
  transition: 0.5s;
}

.toggle-menu-btn1 div::before {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.toggle-menu-btn1 div::after {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

.toggle-menu-btn1.active div {
  background: transparent !important;
}

.toggle-menu-btn1.active div:after {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}

.toggle-menu-btn1.active div::before {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

body .btn.disabled,
body button:disabled {
  pointer-events: none;
}

body .primary-gradient-btn {
  background: var(--primary-gradient-color) !important;
  white-space: nowrap; /* KAD added Jan 21, 2025 to prevent wrap */
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--primary-gradient-shadow) !important;
  outline: none;
  color: var(--light-color) !important;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none !important;
  line-height: 28.8px;
  border-radius: 60px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

body .primary-gradient-btn:hover {
  border-color: var(--light-color) !important;
  background: transparent !important;
  color: var(--light-color) !important;
}

@media (max-width: 767px) {
  body .primary-gradient-btn {
    padding: 10px 24px;
    line-height: 26px;
    column-gap: 7px;
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  body .primary-gradient-btn {
    font-size: 14px;
    line-height: 24px;
  }
}

body .transparent-bg-white-btn {
  background: transparent !important;
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border: 1px solid var(--light-color) !important;
  outline: none;
  color: var(--light-color) !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  border-radius: 60px;
  transition: 0.5s;
}

.copyright-footer {
  color: var(--light-color);
  text-align: center;
  padding: 24px 0;
}

body .transparent-bg-white-btn .icon img {
  width: 24.73px;
}

body .transparent-bg-white-btn:focus,
body .primary-gradient-btn:focus {
  outline: none !important;
}

body .transparent-bg-white-btn:hover {
  background: var(--primary-gradient-color);
  border-color: var(--primary-gradient-shadow);
  /* box-shadow: 0px 0px 20px 0px var(--primary-gradient-shadow); */
}

@media (max-width: 767px) {
  body .transparent-bg-white-btn {
    padding: 10px 24px;
    line-height: 26px;
    column-gap: 7px;
    font-size: 16px;
  }

  body .primary-gradient-btn {
    padding: 10px 24px;
    line-height: 26px;
    column-gap: 7px;
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  body .transparent-bg-white-btn {
    font-size: 14px;
    line-height: 24px;
  }

  body .primary-gradient-btn {
    font-size: 14px;
    line-height: 24px;
  }
}

body .transparent-bg-white-btn .icon {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

body .transparent-bg-white-btn:hover {
  color: var(--light-color);
}

/* ============== start: main-header ============== */
.main-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.main-header .after-login-user-info .primary-gradient-btn:hover {
  border-color: var(--primary-gradient-shadow) !important;
  background: transparent !important;
  color: var(--primary-gradient-shadow) !important;
}

.main-header .after-login-user-info {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 0;
  position: relative;
  padding: 4px 8px 4px 5px;
  border-radius: 60px;
  border: 1px solid var(--primary-fade-color1);
}

.main-header .after-login-user-info .user-icon {
  width: 28px;
  border-radius: 100%;
  height: 28px;
  color: var(--light-color);
}

.main-header .after-login-user-info .user-icon img,
.main-header .after-login-user-info .user-icon svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.main-header .after-login-user-info .arow {
  flex: 0 0 auto;
  color: var(--light-color);
  cursor: pointer;
  position: relative;
}

.main-header .after-login-user-info .arow svg {
  transition: 0.5s;
  width: 12px;
}

.main-header .after-login-user-info .arow.active {
  z-index: 700;
}

.main-header .after-login-user-info .arow.active svg {
  transform: rotate(180deg);
}

.after-login-user-dropdown .user-info {
  border-bottom: 1px solid var(--theme-checkbox-s1-border);
  margin: 0;
  text-align: center;
}

.after-login-user-dropdown .user-info h4 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 14px;
  padding: 10px 16px;
  color: var(--dark-color);
  margin: 0;
}

.after-login-user-dropdown {
  box-shadow: 0px 0px 32px 0px var(--user-dropdown-shadow);
  background-color: var(--light-color);
  position: absolute;
  right: 0;
  width: 260px;
  top: calc(100% + 4px);
  z-index: 700;
  border-radius: 10px;
}

.after-login-user-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 699;
}

.after-login-user-dropdown .dropdown--item {
  padding: 10px 16px;
  display: flex;
  justify-content: center;
}

.after-login-user-dropdown .dropdown-btn {
  padding: 4px 32px;
  font-size: 14px;
}

.main-header .custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header .navbar-brand {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 0;
  color: var(--light-color) !important;
}

.main-header .navbar-brand svg {
  color: var(--subscription-box-active-shadow);
}

.main-header .navbar-collapse {
  flex: unset !important;
  margin-left: auto;
}

.main-header .navbar-collapse .close-btn {
  display: none;
}

.main-header .navbar-nav {
  display: flex;
  align-items: center;
  column-gap: 36px;
}

.main-header .navbar-nav .navbar-text {
  padding: 0 !important;
}

.main-header .navbar-nav .nav-link {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 19.36px !important;
  color: var(--primary-fade-color1) !important;
  padding: 0 !important;
}

.main-header .navbar-nav .nav-link.active {
  color: var(--light-color) !important;
}

.main-header .navbar-nav .nav-link.info-text {
  background-color: var(--light-color) !important;
  padding: 3px 4px !important;
  border-radius: 3px !important;
  color: var(--link-color) !important;
  font-size: 14px !important;
  white-space: nowrap; /* KAD added Jan 26, 2025 */
}

/* ============== end: main-header ============== */
.authentication-page {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 991px) {
  .authentication-page {
    flex-wrap: wrap;
    justify-content: unset;
  }
}

.authentication-page .back-btn {
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 107px;
  height: 40px;
  padding: 8px 27px 8px 13px;
  gap: 8px;
  border-radius: 60px;
  border: 1px solid var(--back-btn-border);
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: var(--light-color);
}

.authentication-page .back-btn .icon {
  font-size: 11px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.authentication-page .image-container {
  width: 100%;
  max-width: 50%;
  position: relative;
  border-radius: 20px;
  /* max-height: 100vh; */
}

@media (max-width: 991px) {
  .authentication-page .image-container {
    max-width: unset;
  }
}

.authentication-page .image-container img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* max-height: 100vh; */
}

@media (max-width: 991px) {
  .authentication-page .image-container img {
    height: 50vw;
    object-position: top center;
  }
}

.authentication-page .form-sec {
  width: 100%;
  max-width: 50%;
  padding: 24px 24px;
  display: flex;
  position: relative;
}

@media (max-width: 991px) {
  .authentication-page .form-sec {
    max-width: unset;
  }
}

.authentication-page .form-sec .form-container {
  width: 100%;
  max-width: calc(450px + 50px);
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.authentication-page .form-sec .form-container .form-container {
  padding: 0 !important;
  max-width: unset !important;
}

@media (max-width: 991px) {
  .authentication-page .form-sec .form-container {
    padding: 50px 15px;
  }
}

@media (max-width: 575px) {
  .authentication-page .form-sec .form-container {
    padding: 32px 12px;
  }
}

.authentication-page
  .form-sec
  .form-container:has(.after-submit-form-status-box) {
  padding: 22px !important;
  max-width: 444px;
}

.submit-btn-lists {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.submit-btn-lists .submit-btn {
  text-decoration: none;
  width: 100%;
  cursor: pointer;
}

/* KAD added all this contact-page stuff so I could style differently than the sign on page Feb 13, 2025 */
.contact-page .form-sec .form-container .headerPart {
  text-align: left;
}

.contact-page .form-sec .form-container .bodyPart .alert {
  margin-top: 16px;
}

.contact-page .form-sec .form-container .headerPart .center-logo {
  margin-bottom: 24px;
}

.contact-page .form-sec .form-container .headerPart h2 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 16px;
}

@media (max-width: 575px) {
  .contact-page .form-sec .form-container .headerPart h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

.contact-page .form-sec .form-container .headerPart h4 {
  color: #afb3d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 12px 0 0;
}

@media (max-width: 575px) {
  .contact-page .form-sec .form-container .headerPart h4 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 4px;
  }

  .contact-page .form-sec {
    padding: 40px 10px 16px;
  }
}

.contact-page .form-sec .form-container .bodyPart .leftRight-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  margin-top: 10px;
}

.contact-page .form-sec .form-container .bodyPart .or {
  color: #aaabc2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.contact-page .form-sec .form-container .bodyPart .more-text {
  margin-top: 24px;
  color: #aaabc2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 575px) {
  .contact-page .form-sec .form-container .bodyPart .more-text {
    margin-top: 8px;
    font-size: 14px;
  }
}

.contact-page .form-sec .form-container .bodyPart .more-text a {
  color: #7d67ff;
  text-decoration: none;
}

.contact-page .form-sec .form-container .bodyPart .more-text .text-link {
  color: #7d67ff;
  text-decoration: none;
  display: inline-block;
}

.contact-page.center-form {
  justify-content: center;
  padding: 35px 0;
}

.contact-page.center-form .form-sec {
  max-width: unset;
}

.contact-page.center-form .form-sec .form-container.w647px {
  max-width: 647px;
}

.contact-page.center-form .form-sec .form-container.w447px {
  max-width: 447px;
}

.contact-page.center-form .form-sec .form-container.w700px {
  max-width: 700px;
}

.contact-page.center-form .form-sec .form-container.w1352px {
  max-width: 1352px;
}

/* .contact-page.center-form .form-sec .form-container.w1352px .headerPart h4 {
  margin-top: 90px;
} */

.contact-page.center-form .form-sec .form-container.w1352px .bodyPart {
  padding-top: 8px;
}

.contact-page.center-form
  .form-sec
  .form-container.w1352px
  .submit-btn-lists {
  margin-top: 16px;
}
/* KAD added all this contact-page stuff so I could style differently than the sign on page Feb 13, 2025 */

.authentication-page .form-sec .form-container .headerPart {
  text-align: center;
}

.authentication-page .form-sec .form-container .bodyPart .alert {
  margin-top: 16px;
}

.authentication-page .form-sec .form-container .headerPart .center-logo {
  margin-bottom: 24px;
}

.authentication-page .form-sec .form-container .headerPart h2 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 16px;
}

@media (max-width: 575px) {
  .authentication-page .form-sec .form-container .headerPart h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

.authentication-page .form-sec .form-container .headerPart h4 {
  color: #afb3d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 12px 0 0;
}

@media (max-width: 575px) {
  .authentication-page .form-sec .form-container .headerPart h4 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 4px;
  }

  .authentication-page .form-sec {
    padding: 40px 10px 16px;
  }
}

.authentication-page .form-sec .form-container .bodyPart .leftRight-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  margin-top: 10px;
}

.authentication-page .form-sec .form-container .bodyPart .or {
  color: #aaabc2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.authentication-page .form-sec .form-container .bodyPart .more-text {
  margin-top: 24px;
  color: #aaabc2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 575px) {
  .authentication-page .form-sec .form-container .bodyPart .more-text {
    margin-top: 8px;
    font-size: 14px;
  }
}

.authentication-page .form-sec .form-container .bodyPart .more-text a {
  color: #7d67ff;
  text-decoration: none;
}

.authentication-page .form-sec .form-container .bodyPart .more-text .text-link {
  color: #7d67ff;
  text-decoration: none;
  display: inline-block;
}

.authentication-page.center-form {
  justify-content: center;
  padding: 35px 0;
}

.authentication-page.center-form .form-sec {
  max-width: unset;
}

.authentication-page.center-form .form-sec .form-container.w647px {
  max-width: 647px;
}

.authentication-page.center-form .form-sec .form-container.w447px {
  max-width: 447px;
}

.authentication-page.center-form .form-sec .form-container.w1352px {
  max-width: 1352px;
}

/* .authentication-page.center-form .form-sec .form-container.w1352px .headerPart h4 {
  margin-top: 90px;
} */

.authentication-page.center-form .form-sec .form-container.w1352px .bodyPart {
  padding-top: 8px;
}

.authentication-page.center-form
  .form-sec
  .form-container.w1352px
  .submit-btn-lists {
  margin-top: 16px;
}

.after-submit-form-status-box {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  border-radius: 10px;
  border: 0px solid #000;
  background: var(--light-color);
}

.after-submit-form-status-box .text h3 {
  color: #070b18;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
}

.after-submit-form-status-box .text p {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
}

.after-submit-form-status-box .submit-btn-lists .submit-btn {
  width: unset !important;
  cursor: pointer;
  text-decoration: none;
}

/* =================== start: agent-builder-specification-sec =================== */
.agent-builder-specification-sec {
  padding: 0 0 100px;
}

@media (max-width: 1350px) {
  .agent-builder-specification-sec {
    padding: 0 0 65px;
  }
}

.agent-builder-specification-sec .sec-wrapper {
  border-radius: 20px;
  background-image: url(./assets/images/agent-builder-specification-sec.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 104px 0px rgba(6, 8, 17, 0.3);
  padding: 65px 55px;
}

@media (max-width: 1350px) {
  .agent-builder-specification-sec .sec-wrapper {
    padding: 45px 35px;
  }
}

@media (max-width: 575px) {
  .agent-builder-specification-sec .sec-wrapper {
    padding: 24px 16px;
  }
}

.agent-builder-specification-sec .sec-wrapper .item-lists {
  display: flex;
  flex-wrap: wrap;
  margin: -30px;
}

@media (max-width: 1350px) {
  .agent-builder-specification-sec .sec-wrapper .item-lists {
    margin: -16px;
  }
}

@media (max-width: 575px) {
  .agent-builder-specification-sec .sec-wrapper .item-lists {
    margin: -10px;
  }
}

.agent-builder-specification-sec .sec-wrapper .item-lists .item-col {
  padding: 30px;
  width: 100%;
  max-width: 25%;
}

@media (max-width: 1350px) {
  .agent-builder-specification-sec .sec-wrapper .item-lists .item-col {
    padding: 16px;
  }
}

@media (max-width: 991px) {
  .agent-builder-specification-sec .sec-wrapper .item-lists .item-col {
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .agent-builder-specification-sec .sec-wrapper .item-lists .item-col {
    max-width: unset;
  }
}

@media (max-width: 575px) {
  .agent-builder-specification-sec .sec-wrapper .item-lists .item-col {
    padding: 10px;
  }
}

.agent-builder-specification-sec .sec-wrapper .item-lists .item-col .item-box {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (max-width: 1350px) {
  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box {
    row-gap: 24px;
  }
}

@media (max-width: 575px) {
  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box {
    row-gap: 20px;
  }
}

.agent-builder-specification-sec
  .sec-wrapper
  .item-lists
  .item-col
  .item-box
  .icon {
  height: 52px;
  flex: 0 0 auto;
  display: flex;
}

@media (max-width: 575px) {
  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box
    .icon {
    height: 42px;
  }

  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box
    .icon
    svg {
    height: 100%;
    width: auto;
  }
}

.agent-builder-specification-sec
  .sec-wrapper
  .item-lists
  .item-col
  .item-box
  .text {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

@media (max-width: 575px) {
  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box
    .text {
    row-gap: 10px;
  }
}

.agent-builder-specification-sec
  .sec-wrapper
  .item-lists
  .item-col
  .item-box
  .text
  h3 {
  color: var(--light-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

@media (max-width: 1350px) {
  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box
    .text
    h3 {
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width: 575px) {
  .agent-builder-specification-sec
    .sec-wrapper
    .item-lists
    .item-col
    .item-box
    .text
    h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

.agent-builder-specification-sec
  .sec-wrapper
  .item-lists
  .item-col
  .item-box
  .text
  p {
  color: var(--light-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  font-size: 14px;
  line-height: 24px;
}

/* =================== end: agent-builder-specification-sec =================== */

/* =================== start: theme-modal-s1 =================== */
.theme-modal-s1 {
}

.modal-backdrop {
  background: var(--modal-backdrop) !important;
  opacity: 0.9 !important;
}

.theme-modal-s1.w600px .modal-dialog {
  width: 100% !important;
  max-width: 600px !important;
}

.theme-modal-s1.w1200px .modal-dialog {
  width: 100% !important;
  max-width: 1200px !important;
}

.theme-modal-s1 .modal-content {
  border-radius: 12px;
  background: linear-gradient(
    to right bottom,
    #191b5b,
    #0f1a4c,
    #0b173c,
    #0b132c,
    #090d1c
  );
}

.theme-modal-s1 .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-border1);
  padding: 24px 32px;
}

.theme-modal-s1 .modal-header .modal-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 110%;
  color: var(--light-color);
}

.theme-modal-s1 .modal-body {
  padding: 32px 32px;
}

.theme-modal-s1 .modal-header .btn-close {
  background-color: transparent;
  width: 18px;
  height: 18px;
  position: relative;
  border: 0;
}

.theme-modal-s1 .modal-header .btn-close::before,
.theme-modal-s1 .modal-header .btn-close::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--light-color);
  border-radius: 3px;
  transition: 0.5s;
}

.theme-modal-s1 .modal-header .btn-close:after {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}

.theme-modal-s1 .modal-header .btn-close::before {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

.theme-modal-s1 .form-submit-group {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.theme-modal-s1 .form-submit-group .submit-btn {
}
.theme-modal-s1 .modal-footer {
}
.theme-modal-s1 .modal-footer .theme-btn {
  height: 42px;
}
/* =================== end: theme-modal-s1 =================== */

/* =================== start: subscription =================== */
.subscription-page {
  padding: 80px 0;
}

@media (max-width: 991px) {
  .subscription-page {
    padding: 40px 0 60px;
  }
}

@media (max-width: 575px) {
  .subscription-page {
    padding: 24px 0 50px;
  }
}

.subscription-page .page-headerpart {
  text-align: center;
  width: 100%;
  max-width: 926px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .subscription-page .page-headerpart {
    max-width: 720px;
  }
}

.subscription-page .page-headerpart .tagsec {
  display: flex;
  justify-content: center;
}

.subscription-page .page-headerpart .tagsec .tagbox {
  display: flex;
  padding: 5px 23px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid var(--tag-box-border);
  color: var(--light-color);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 30.6px;
}

@media (max-width: 1350px) {
  .subscription-page .page-headerpart .tagsec .tagbox {
    font-size: 16px;
    line-height: 28px;
  }
}

.subscription-page .page-headerpart h1 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 991px) {
  .subscription-page .page-headerpart h1 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .subscription-page .page-headerpart h1 {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .subscription-page .page-headerpart h1 {
    font-size: 35px;
  }
}

.subscription-page .page-headerpart h4 {
  color: var(--light-faeed-text-color5);
  font-size: 18px;
  font-weight: 500;
  line-height: 30.6px;
  margin: 32px 0 0;
}

@media (max-width: 767px) {
  .subscription-page .page-headerpart h4 {
    font-size: 16px;
    line-height: 28px;
    margin: 16px 0 0;
  }
}

.subscription-container {
  width: 100%;
  /* max-width: 1124px;
  margin: 0 auto; */
}

.subscription-lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -11px;
  padding-top: 62px;
}

@media (max-width: 767px) {
  .subscription-lists {
    padding-top: 42px;
  }
}

.subscription-lists .subscription-col {
  padding: 11px;
  width: 100%;
  max-width: 25%;
}

@media (max-width: 991px) {
  .subscription-lists .subscription-col {
    max-width: 33.33%;
  }
}

@media (max-width: 767px) {
  .subscription-lists .subscription-col {
    max-width: 50%;
  }
}

@media (max-width: 575px) {
  .subscription-lists .subscription-col {
    max-width: unset;
  }
}

.subscription-lists .subscription-col .subscription-box {
  padding: 28px;
  border-radius: 16px;
  border: 1.2px solid var(--subscription-box-border);
  background: var(--subscription-box-bg);
  height: 100%;
  position: relative;
}

.subscription-lists .subscription-col .subscription-box .pre-stamp {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  top: -20px;
}

.subscription-lists .subscription-col .subscription-box .mostPopular-tag {
  color: var(--light-color);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 27.2px;
  display: flex;
  padding: 4px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
  background: var(--secondary-gradient-color);
}

.subscription-lists .subscription-col .subscription-box h3 {
  color: var(--light-color);
  font-size: 26px;
  margin: 0;
  font-weight: 600;
  line-height: 36.4px;
}

.subscription-lists .subscription-col .subscription-box h5 {
  color: var(--light-faeed-text-color3);
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
}

.subscription-lists .subscription-col .subscription-box .top-info {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.subscription-lists .subscription-col .subscription-box .divider {
  background: var(--divider-bg1);
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.subscription-lists .subscription-col .subscription-box h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  background: linear-gradient(
    71deg,
    var(--subscription-h2-gradient1) -12.36%,
    var(--subscription-h2-gradient2) 86.1%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subscription-lists .subscription-col .subscription-box .center-info {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.subscription-lists .subscription-col .subscription-box .more-action {
  display: flex;
}

.subscription-lists .subscription-col .subscription-box .more-action .get-btn {
  width: 100%;
  text-decoration: none;
  font-size: 16px;
  line-height: 25.6px;
}

.subscription-lists .subscription-col .subscription-box .fec-lists {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0;
  margin: 32px 0 0;
}

.subscription-lists .subscription-col .subscription-box .fec-lists li {
  display: flex;
  align-items: flex-start;
  color: var(--light-color);
  font-size: 16px !important;
  font-weight: 500 !important;
  column-gap: 10px;
  line-height: 24px !important;
}

.subscription-lists .subscription-col .subscription-box .fec-lists li .icon {
  display: flex;
  align-items: center;
  margin: 4.5px 0 0;
  flex: 0 0 auto;
}

.subscription-lists .subscription-col .subscription-box .fec-lists li strong {
  font-weight: 700;
}

.subscription-lists .subscription-col .subscription-box.selected {
  border-color: var(--subscription-box-active-border);
  background: var(--subscription-box-active-bg);
}

.subscription-lists .subscription-col .subscription-box.selected .divider {
  background: var(--subscription-box-active-diver);
}

.subscription-lists .subscription-col .subscription-box.selected .get-btn {
  pointer-events: none;
}

.subscription-bottom-text {
  text-align: center;
  margin-top: 50px;
  color: white;
}

.subscription-bottom-text p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30.6px;
  color: var(--light-color);
  margin: 0;
}

/* =================== end: subscription =================== */

/* =================== start: consent-to-bill-msg-sec =================== */
.consent-to-bill-msg-sec {
  padding: 60px 0;
}

.consent-to-bill-msg-wrapper {
  width: 100%;
  max-width: 645px;
  margin: 0 auto;
}

.consent-to-bill-msg-wrapper.for-info-card {
  max-width: 1024px;
  border: 1.2px solid var(--subscription-box-border);
  padding: 32px;
  border-radius: 16px;
}

.consent-to-bill-msg-wrapper.mt {
  margin-top: 80px;
}

.consent-to-bill-msg-wrapper h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 72.61px;
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--primary-gradient-shadow);
  margin-bottom: 32px;
}

.consent-to-bill-msg-wrapper h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--primary-gradient-shadow);
  text-align: center;
  margin-bottom: 24px;
}

.consent-to-bill-msg-wrapper h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: var(--light-color);
  margin-bottom: 8px;
}

.consent-to-bill-msg-wrapper h4 strong {
  font-weight: 600;
  color: var(--light-color);
}

.consent-to-bill-msg-wrapper .paragraph-text,
.consent-to-bill-msg-wrapper p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: var(--light-faeed-text-color5);
}

.consent-to-bill-msg-wrapper .more-action {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  column-gap: 16px;
  margin-top: 32px;
}

.consent-to-bill-msg-wrapper .more-action .action-btn {
  min-width: 224px;
  max-width: 100%;
  height: 52px;
  padding-top: 0;
  padding-bottom: 0;
}

/* =================== end: consent-to-bill-msg-sec =================== */

/* =================== start: privacyComponent-sec =================== */
.privacyComponent-sec {
  padding: 32px 0;
  position: relative;
}

.privacyComponent-sec .version-changer {
  position: absolute;
  top: 45px;
  right: 0;
  width: 90px;
}

@media (max-width: 480px) {
  .privacyComponent-sec .version-changer {
    top: 93px;
  }
}

.privacyComponent-sec .version-changer .custom-form-s1 {
  height: 42px !important;
  background-position: center right 11px !important;
  padding: 0 14px;
  background-size: 12px !important;
  padding-right: 28px !important;
}

.privacyComponent-sec p,
.privacyComponent-sec h1,
.privacyComponent-sec h2,
.privacyComponent-sec h3,
.privacyComponent-sec h4,
.privacyComponent-sec h5,
.privacyComponent-sec h6,
.privacyComponent-sec ul {
  margin-bottom: 16px;
  margin-left: 0 !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--light-color);
}

.privacyComponent-sec h1 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0 0 24px;
}

.privacyComponent-sec ul,
.privacyComponent-sec ol {
  margin: 0 0 16px 20px !important;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-left: 1rem;
}

.privacyComponent-sec ul.ml-0,
.privacyComponent-sec ol.ml-0 {
  margin-left: 0 !important;
}

.privacyComponent-sec ul.pl-0,
.privacyComponent-sec ol.pl-0 {
  padding-left: 0 !important;
}

.privacyComponent-sec ul.list-style-position-inside,
.privacyComponent-sec ol.list-style-position-inside {
  list-style-position: inside !important;
}

.privacyComponent-sec ul ul,
.privacyComponent-sec ol ul,
.privacyComponent-sec ul ol,
.privacyComponent-sec ol ol {
  margin: 0 0 16px 8px !important;
  padding-left: 8px;
}

.privacyComponent-sec ol {
  list-style: auto !important;
}

.privacyComponent-sec ul {
  list-style: disc;
}

/* =================== end: privacyComponent-sec =================== */

/* =================== start: admin-page-layout =================== */
.admin-page-layout {
  padding: 80px 0;
}

.admin-page-layout .custom-container {
  display: flex;
  flex-direction: column;
  /* max-width: 1349px; */
  row-gap: 32px;
}

.admin-page-layout .left-side-menu {
  /* background: var(--light-color); */
  width: 100%;
  /* max-width: 372px; */
  /* border-radius: 8px; */
  overflow: hidden;
  /* min-height: 400px; */
}

.admin-page-layout .left-side-menu .menu-lists {
  display: flex;
  column-gap: 24px;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--primary-fade-color1);
  list-style: none;
}

/* .admin-page-layout .left-side-menu .menu-lists .menu-item:not(:last-child) {
  border-bottom: 1px solid var(--primary-fade-color1);
} */
.admin-page-layout .left-side-menu .menu-lists button {
  background-color: transparent;
  border: 0;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--primary-fade-color1) !important;
  border-bottom: 2px solid transparent;
  width: 100%;
  padding: 10px 0;
}

.admin-page-layout .left-side-menu .menu-lists button:hover {
  color: var(--link-color) !important;
  background-color: transparent;
}

.admin-page-layout .left-side-menu .menu-lists button.active {
  color: var(--light-color) !important;
  background-color: transparent;
  border-color: var(--light-color);
}

/* .admin-page-layout .right-content .authentication-page.center-form{
  padding: 0 !important;
} */
.admin-page-layout .right-content {
  width: 100%;
}

.admin-page-layout .right-content .table-listing-page-wrapper {
  max-width: unset !important;
}

.admin-page-layout .right-content h3 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 16px;
}

.admin-page-layout .right-content h4 {
  color: #afb3d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.admin-page-layout .right-content .MuiPaper-root {
  padding: 0 !important;
  margin: 32px 0 0 !important;
}

/* =================== end: admin-page-layout =================== */

/* =================== start: table-listing-page-s1 =================== */
.table-listing-page-s1 {
  padding: 80px 0;
}

.table-listing-page-wrapper {
  width: 100%;
  max-width: 1352px;
  margin: 0 auto;
  padding: 32px;
  border-radius: 10px;
  background-color: var(--sec-bg);
}

.table-listing-page-wrapper:has(.authentication-page.center-form) {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

.table-listing-page-wrapper .bQIUDX,
.table-listing-page-wrapper .bnqToK {
  padding: 0 0 16px;
  overflow: unset !important;
}

.table-listing-page-wrapper .sc-hmdomO,
.table-listing-page-wrapper .dcTwyf {
  color: var(--light-color);
}

.table-listing-page-wrapper .jzrolw {
  border-radius: 10px;
}

.table-listing-page-wrapper .rdt_TableHeader .sc-gFqAkR {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
}

.table-listing-page-wrapper .rdt_TableHeader .filter-icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 45px;
  z-index: 2;
  position: relative;
  height: 45px;
  padding: 0;
  margin: 0 !important;
}

.table-listing-page-wrapper .rdt_TableHeader .filter-icon-btn.import svg {
  transform: rotate(180deg);
}

.table-listing-page-wrapper .rdt_TableHeader .filter-icon-btn:focus {
  box-shadow: none !important;
}

.table-listing-page-wrapper header .search-div .form-control {
}

.table-listing-page-wrapper header .search-div .clear-btn {
  padding: 0 !important;
  background-color: transparent !important;
  width: unset !important;
  height: unset !important;
  color: var(--light-color) !important;
}

.table-listing-page-wrapper .rdt_TableHeadRow {
  border-bottom: 0 !important;
}

.table-listing-page-wrapper .rdt_TableBody .rdt_TableRow {
  border: 0 !important;
  /* border-bottom: 1px solid var(--light-faeed-text-color5) !important; */
  border-bottom: 0 !important;
  outline: none !important;
}

.table-listing-page-wrapper .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background-color: var(--table-even-bg);
  /* !important; */
}

.table-listing-page-wrapper .rdt_TableBody .rdt_TableRow:nth-child(even):hover {
  background-color: var(--table-odd-color);
  color: var(--dark-color) !important;
}

.table-listing-page-wrapper .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background-color: var(--table-odd-bg);
  /* !important; */
}

.table-listing-page-wrapper .rdt_TableBody .rdt_TableRow:nth-child(odd):hover {
  background-color: var(--table-even-color);
  /* color: var(--light-color) !important; */
}

.table-listing-page-wrapper
  .rdt_TableBody
  .rdt_TableRow:nth-child(even)
  .rdt_TableCell {
  color: var(--light-color);
}

.table-listing-page-wrapper .rdt_Pagination {
  color: var(--light-color);
}

.table-listing-page-wrapper .rdt_Pagination button svg path:first-child {
  fill: var(--light-color);
}

.table-listing-page-wrapper .rdt_Pagination button:disabled {
  opacity: 0.5;
}

/* =================== end: table-listing-page-s1 =================== */

/* =================== start: edit-create-common-form-sec =================== */
.table-listing-page-wrapper:has(.edit-create-common-form-sec) {
  padding: 0 !important;
  background: transparent !important;
  margin: 0 !important;
  box-shadow: none !important;
  border: 0 !important;
}

.custom-container .edit-create-common-form-sec .custom-container {
  max-width: unset;
  padding: 0;
}

.edit-create-common-form-sec h1 {
  color: var(--light-color);
  text-shadow: 0px 0px 18px var(--white-shadow-text-blue);
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 16px;
}

.edit-create-common-form-sec h4 {
  color: #afb3d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.edit-create-common-form-sec .form-submit-action {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.edit-create-common-form-sec .sub-heading,
.common-from-sub-heading {
  background: var(--primary-gradient-color);
  color: var(--light-color);
  padding: 6px 9px;
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  cursor: pointer;
}

.edit-create-common-form-sec .sub-heading .accord-btn,
.common-from-sub-heading .accord-btn {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 0 0 auto;
  border: 1px solid var(--light-color);
  outline: none;
  background-color: var(--light-color);
  color: var(--link-color);
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  justify-content: center;
}

.edit-create-common-form-sec .sub-heading .accord-btn svg,
.common-from-sub-heading .accord-btn svg {
  transition: 0.5s;
}

.edit-create-common-form-sec .sub-heading .accord-btn.active svg,
.common-from-sub-heading .accord-btn.active svg {
  transform: rotateX(180deg);
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}

.text-link-white-btn {
  color: var(--light-color);
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.2px !important;
  padding: 0 !important;
}

/* =================== end: edit-create-common-form-sec =================== */
.main-header .navbar-nav .primary-gradient-btn {
  padding: 4px 24px;
  font-size: 14px;
}

.file-uploader-s1 {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  height: 52px;
  width: 100%;
  transition: 0.3s;
  padding: 0 16px;
  border: 1px solid var(--light-color);
  color: var(--light-color);
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.2px !important;
  column-gap: 8px;
}

.file-uploader-s1 .icon {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.file-uploader-s1 .text {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
}

.file-uploader-s1 input[type="file"] {
  position: absolute;
  top: 0;
  left: -100%;
  width: 300% !important;
  opacity: 0;
  height: 100%;
  appearance: none;
}

.agents-view-page-top-button {
  display: flex;
  column-gap: 10px;
}

.agents-view-page-top-button .primary-gradient-btn {
  padding: 8px 20px;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap; /* KAD added Jan 26, 2025 to prevent wrap */
  gap: unset;
}

/* start: custom tooltip */
[data-toggle2="custom-tooltip"] {
  display: inline-block;
  position: relative;
}

/* ORIG
[data-toggle2="custom-tooltip"]::before {
  content: "";
  clear: both;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: all 0.25s ease 0s;
  z-index: 99;
  top: calc(100% - 2px);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 10px solid #080b1e;
}

[data-toggle2="custom-tooltip"]::after {
  content: attr(custom-title2);
  clear: both;
  background: #080b1e;
  border-radius: 3px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  top: calc(100% + 5px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  display: flex;
  opacity: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: var(--light-color);
  white-space: pre;
  transition: all 0.25s ease 0s;
  z-index: 99;
}
*/

[data-toggle2="custom-tooltip"]::before {
  content: "";
  clear: both;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: all 0.25s ease 0s;
  z-index: 99;
  bottom: calc(100% - 100px);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 10px solid #080b1e;
}

[data-toggle2="custom-tooltip"]::after {
  content: attr(custom-title2);
  clear: both;
  background: #080b1e;
  border-radius: 3px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  top: calc(-35px - 100%);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  display: flex;
  opacity: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: var(--light-color);
  white-space: pre;
  transition: all 0.25s ease 0s;
  z-index: 99;
}

[data-toggle2="custom-tooltip"]:hover::after,
[data-toggle2="custom-tooltip"]:hover:before {
  visibility: visible;
  opacity: 1;
}

/* end: custom tooltip */

.sec-all-text-white p {
  color: var(--light-color);
  margin: 0;
}

.advertisement-alert {
  position: relative;
  z-index: 10;
  margin-bottom: 5px;
}

.advertisement-alert.hide {
  display: none !important;
}

.advertisement-alert .custom-container {
  display: flex;
  justify-content: center;
}

.advertisement-alert .advertisement-alert-wrapper {
  background: var(--secondary-gradient-color);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 10px;
  border-radius: 8px;
  width: 100%;
  max-width: 767px;
}

.advertisement-alert .advertisement-alert-wrapper p {
  font-weight: 500;
  color: var(--light-color);
  margin: 0;
}

.advertisement-alert .advertisement-alert-wrapper p a {
  font-weight: 500;
  color: var(--light-color);
  margin: 0;
}

.advertisement-alert .advertisement-alert-wrapper .alert-action {
  margin-left: auto;
  flex: 0 0 auto;
}

.advertisement-alert .advertisement-alert-wrapper .closebtn {
  display: flex;
  align-items: center;
  color: var(--light-color);
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  margin: 0;
}

body .small-primary-btn {
  background: var(--primary-gradient-color) !important;
  display: flex;
  padding: 0 8px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--primary-gradient-shadow) !important;
  outline: none;
  color: var(--light-color) !important;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none !important;
  line-height: 24px;
  border-radius: 60px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

body .small-primary-btn:hover {
  border-color: var(--light-color) !important;
  background: transparent !important;
  color: var(--light-color) !important;
}

/* KAD added Jan 21, 2025, for the Back button on Create Edit..but might be a better way */
body .medium-primary-btn {
  background: var(--primary-gradient-color) !important;
  display: flex;
  padding: 8px 20px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--primary-gradient-shadow) !important;
  outline: none;
  color: var(--light-color) !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
  line-height: 24px;
  border-radius: 60px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

body .medium-primary-btn:hover {
  border-color: var(--light-color) !important;
  background: transparent !important;
  color: var(--light-color) !important;
}

.input-with-add-preview-lists .plus-btn {
  padding: 0;
  width: 45px;
  font-size: 20px;
  height: 45px;
}

.input-with-add-preview-lists .input-with-add-btn {
  display: flex;
  column-gap: 10px;
}

.input-with-add-preview-lists .submit-btn {
  padding: 4px 10px;
  font-size: 14px;
  min-width: 95px;
  flex: 0 0 auto;
}

.input-with-add-preview-lists .submit-btn:disabled {
  opacity: 0.5;
}

.droppable-ul-lists {
  list-style: none;
  margin: 16px 0 0;
  padding: 0;
  width: 300px;
}

.droppable-ul-lists .droppable-li-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  color: var(--light-color);
  font-size: 16px;
}

.droppable-ul-lists .droppable-li-item:not(:last-child) {
  border-bottom: 1px solid var(--light-faeed-text-color2);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.droppable-ul-lists .droppable-li-item .tick {
  display: flex;
  align-items: center;
  font-size: 18px;
  flex: 0 0 auto;
}

.droppable-ul-lists .droppable-li-item .action-div {
  margin-left: auto;
  display: flex;
  gap: 20px;
}

.input-with-add-preview-lists .droppable-li-item .action-div .plus-btn {
  width: 30px;
  font-size: 16px;
  height: 30px;
}

.table-listing-page-wrapper .row .common-from-sub-heading {
  margin-top: 10px;
  border-radius: 10px;
}

.table-listing-page-wrapper .rdt_TableHeader {
  overflow: unset !important;
  color: var(--light-color);
}

.text-white,
.text-white .rdt_TableHeader {
  color: var(--light-color) !important;
}

.label-with-right-add-btn {
  display: flex;
  align-items: center;
  margin: 0 0 8px;
}

.label-with-right-add-btn label {
  margin: 0 !important;
}

.label-with-right-add-btn .small-primary-btn {
  margin-left: 4px;
}

.hash-link {
  color: var(--light-color) !important;
  text-decoration: none !important;
}

.hash-link :hover {
  text-decoration: none;
}

.paragraph-text {
  color: var(--light-color);
  align-items: center;
}
.subscription-page ~ .paragraph-text {
  text-align: center;
}
/* Makes date input calendar icon white */
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Gallery container to control the grid layout */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  /* Responsive grid */
  gap: 16px;
  /* Space between items */
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}

/* Individual item container for each image */
.gallery-item {
  overflow: hidden;
  /* Prevents images from overflowing their container */
  border-radius: 8px;
  /* Optional: rounded corners */
  background-color: #f0f0f0;
  /* Optional: background color for empty space */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
  position: relative;
}

/* Styling for images */
.gallery-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* Ensures images maintain aspect ratio */
}

.gallery-image:hover {
  cursor: pointer;
  opacity: 0.6;
  /* filter: brightness(1.2); */
}

/* Container styles for both graphs */
.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Heading styles */
.graph-title {
  color: var(--light-color);
}

/* Loading message styles */
.loading-message {
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-title {
  color: var(--light-color);
}

.close-icon {
  text-align: right;
}

.icon-button {
  color: var(--light-color) !important;
}

.popup-gallery-dialog .MuiDialog-paper {
  background: linear-gradient(
    to right bottom,
    #191b5b,
    #0f1a4c,
    #0b173c,
    #0b132c,
    #090d1c
  );
  border-radius: 16px;
  border: 2px solid white;
  width: 90%;
}

.gallery .small-primary-btn {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-icon {
  transition: color 0.3s ease;
  cursor: pointer;
}

.delete-icon:hover {
  color: rgb(255, 255, 255);
}

.img-delete .delete-icon {
  transition: color 0.3s ease;
  cursor: pointer;
  height: 20px;
}

.img-delete .delete-icon:hover {
  color: rgb(165, 165, 165);
}

.img-delete {
  position: absolute;
  top: 0px;
  right: 0px;
  /* background-color: rgba(255, 255, 255, 0.7); */
  border-radius: 50%;
  padding: 0px;
  z-index: 10; /* Ensures the icon is above the image */
}

.img-delete-icon {
  color: black;
}

/* start: custom tooltip */
[data-toggle2="custom-tooltip"] {
  display: inline-block;
  position: relative;
}

[data-toggle2="custom-tooltip"]::before {
  content: "";
  clear: both;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: all 0.25s ease 0s;
  z-index: 99;
  top: calc(100% - 2px);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 10px solid #080b1e;
}

[data-toggle2="custom-tooltip"]::after {
  content: attr(custom-title2);
  clear: both;
  background: #080b1e;
  border-radius: 3px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  top: calc(100% + 5px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  display: flex;
  opacity: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: var(--light-color);
  white-space: pre;
  transition: all 0.25s ease 0s;
  z-index: 99;
}

[data-toggle2="custom-tooltip"]:hover::after,
[data-toggle2="custom-tooltip"]:hover:before {
  visibility: visible;
  opacity: 1;
}

/* end: custom tooltip */
.sec-all-text-white p {
  color: var(--light-color);
}

.label-with-right-add-btn {
  display: flex;
  align-items: center;
  margin: 0 0 8px;
}

.label-with-right-add-btn label {
  margin: 0 !important;
}

.label-with-right-add-btn .small-primary-btn {
  margin-left: 4px;
}

/* start: cookie-alert */
.cookie-alert-popup [data-custom-class="body"],
.cookie-alert-popup [data-custom-class="body"] * {
  background: transparent !important;
}

.cookie-alert-popup [data-custom-class="title"],
.cookie-alert-popup [data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

.cookie-alert-popup [data-custom-class="subtitle"],
.cookie-alert-popup [data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

.cookie-alert-popup [data-custom-class="heading_1"],
.cookie-alert-popup [data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

.cookie-alert-popup [data-custom-class="heading_2"],
.cookie-alert-popup [data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

.cookie-alert-popup [data-custom-class="body_text"],
.cookie-alert-popup [data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

.cookie-alert-popup [data-custom-class="link"],
.cookie-alert-popup [data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}
.cookie-alert-popup .modal-header {
  padding: 12px 16px;
  /* border: 0; */
}
.cookie-alert-popup .modal-header .modal-title {
  font-size: 24px;
  line-height: 26px;
}

.cookie-alert-popup .modal-body {
  padding: 10px 16px 0 !important;
}
.cookie-alert-popup .modal-dialog-centered {
  justify-content: flex-end !important;
}
.cookie-alert-popup .modal-footer {
  padding: 12px 16px;
  border: 0;
}
.cookie-alert-popup .modal-body {
  height: 140px !important;
}
.theme-modal-s1.cookie-alert-popup .modal-footer .theme-btn {
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px;
}
/* end: cookie-alert */

.back-btn-common-s1 {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 107px;
  height: 40px;
  padding: 8px 27px 8px 13px;
  gap: 8px;
  border-radius: 60px;
  border: 1px solid var(--back-btn-border);
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: var(--light-color);
}

.back-btn-common-s1 .icon {
  font-size: 11px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.input-with-right-action-x {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
